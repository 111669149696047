/* eslint-disable camelcase */
import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Page from '../layout/Page';
import Section from '../components/common/Section';
import Header from '../components/common/Header';
import Paragraph from '../components/common/Paragraph';

import { Column } from '../components/Column';

import { COLORS, COLUMN_ICON_SIZE } from '../constants.styles';
import { ColumnWrapper } from '../page-styles/prospective.styled';

export default ({ data }) => {
  const featuresColumns = get(data, 'prismicProspectivePage.data.features.document.data');

  const downloadColumns = get(data, 'prismicProspectivePage.data.downloads.document.data');

  return (
    <Page>
      <Section theme={COLORS.WHITE}>
        <div className="container">
          <Header>{get(data, 'prismicProspectivePage.data.header')}</Header>
          <Paragraph>{get(data, 'prismicProspectivePage.data.description.html')}</Paragraph>
        </div>
      </Section>
      <Section theme={COLORS.LIGHT_GREY}>
        <div className="container">
          <ColumnWrapper className="row">
            {get(featuresColumns, 'body', []).map((column, index) => {
              const { icon, header, description, tail_link, link_text } = column.primary;
              return (
                <Column
                  key={index}
                  icon={{
                    url: icon.url,
                    width: COLUMN_ICON_SIZE,
                    height: COLUMN_ICON_SIZE,
                  }}
                  header={header.text}
                  descriptionHTML={description.html}
                  link={tail_link.text}
                  linkText={link_text.text}
                />
              );
            })}
          </ColumnWrapper>
        </div>
      </Section>
      <Section theme={COLORS.WHITE}>
        <div className="container">
          <ColumnWrapper className="row">
            {get(downloadColumns, 'body', []).map((column, index) => {
              const { icon, media_link, media_text, description } = column.primary;
              return (
                <Column
                  key={index}
                  icon={{
                    url: icon.url,
                    width: COLUMN_ICON_SIZE,
                    height: COLUMN_ICON_SIZE,
                  }}
                  iconLink={media_link.url}
                  header={media_text.html}
                  descriptionHTML={description.html}
                />
              );
            })}
          </ColumnWrapper>
        </div>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicProspectivePage {
      data {
        header
        description {
          html
        }
        features {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicColumns {
                data {
                  body {
                    ... on PrismicColumnsBodyColumn {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        header {
                          text
                        }
                        description {
                          html
                        }
                        tail_link
                        link_text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        downloads {
          id
          ... on PrismicLinkType {
            document {
              ... on PrismicDownloadColumn {
                data {
                  body {
                    ... on PrismicDownloadColumnBodyDownloadable {
                      primary {
                        icon {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                        description {
                          html
                        }
                        media_link {
                          url
                        }
                        media_text {
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
